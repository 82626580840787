@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Poppins';
  src: url('fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('fonts/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('fonts/Poppins-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url('fonts/Poppins-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Poppins, Arial, sans-serif;
  background: #0b0b22;
}

a {
  text-decoration: none;
  color: inherit;
}

.panel {
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  flex-direction: column;
  font-size: 21px;
  font-weight: 400;
  line-height: 45.9px;
  text-align: left;
  padding: 5px;
}

.panel1 {
  background: url('assets/street-level-night.png') no-repeat center center/cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7); /* #000000B2 in hex */
}

.first-panel-content {
  z-index: 1;
  max-width: 1042px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 200px;
  margin: 0 auto;
}

/* Add responsive adjustments */
@media (max-height: 800px) {
  .first-panel-content {
    padding-top: 180px;
  }

  .first-panel-content h1 {
    font-size: 50px;
    line-height: 60px;
  }

  .first-panel-content .subtitle {
    font-size: 21px;
    line-height: 32px;
  }
}

@media (max-height: 600px) {
  .first-panel-content {
    padding-top: 160px;
  }

  .first-panel-content h1 {
    font-size: 40px;
    line-height: 50px;
  }

  .first-panel-content .subtitle {
    font-size: 18px;
    line-height: 28px;
  }
}

/* .panel2 {
  background: #0b0b22;
}

.panel4 {
  background: #0b0b22;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 100px;
} */

.flex-content {
  padding: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 0 90px;
  justify-content: center;
  z-index: 1;
}

.bullet-point-panel-background {
  background: url('assets/cityscape_background.jpg') no-repeat center
    center/cover;
}

.footer {
  position: static; /* or remove position property */
  margin-top: auto;
  width: 100%;
  height: 160px; /* or use min-height if needed */
  background: #131224;
  border-color: #702fd2;
  border-style: solid solid none solid;
  border-radius: 108px 108px 0 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center; /* Fixed typo */
  justify-content: center;
}

.footer .logo {
  height: 72px;
  max-width: 90%;
}

.footer-link {
  font-family: Poppins;
  font-size: 21px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  color: white;
  margin: auto 30px;
}

.header {
  height: 62px;
  position: absolute;
  top: 94px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  max-width: 1200px;
  padding: 15px 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1000;
  display: flex;
  align-items: center;
  color: white;
  flex-wrap: wrap;
  justify-content: center;
}

@media (max-width: 768px) {
  .header {
    position: relative; /* Change position to relative */
    top: 0; /* Remove top positioning */
    left: 0; /* Remove left positioning */
    transform: none; /* Remove transform */
    width: 100%; /* Set width to 100% */
    margin: 0 auto; /* Center the header */
    padding: 15px 0; /* Keep padding as needed */
  }

  .first-panel-content {
    padding-top: 100px; /* Add padding if needed for spacing */
  }
}

.header .logo {
  height: 82px;
  margin-right: auto;
  max-width: 90%;
}

.book-demo-btn {
  display: flex;
  font-size: 21px;
  align-items: center;
  justify-content: center;
  background: #e2b088;
  height: 62px;
  width: 230px;
  border-radius: 4.5px;
  box-shadow: 0px 22.5px 45px 0px #0000001a;
  text-align: center;
  color: black;
  text-decoration: none;
  font-size: 21px;
  transition: background-color 0.3s ease; /* Smooth transition */
  line-height: 23px;
}

.book-demo-btn:hover {
  background: #d29250; /* Darker orange on hover */
}

h1 {
  font-family: Poppins;
  font-size: 70px;
  font-weight: 500;
  line-height: 80px;
  text-align: center;
  color: white;
}

@media (max-width: 768px) {
  h1 {
    font-size: 38px;
  }
}

.subtitle {
  margin-top: 55px;
  font-family: Poppins;
  font-size: 27px;
  font-weight: 400;
  line-height: 40px;
  text-align: center;
}

.custom-list {
  list-style: none; /* Remove default bullets */
  padding-left: 0; /* Remove default padding */
}

.custom-list li {
  position: relative; /* Position for pseudo-element */
  padding-left: 70px; /* Space for the custom bullet */
  margin-bottom: 35px; /* Space between list items */
}

.custom-list li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 55px;
  height: 55px;
  background-color: green; /* Default color */
  border-radius: 50%;
  background-image: url('assets/lightbulb.png');
  background-size: 30px 30px; /* Size of the lightbulb image */
  background-position: center;
  background-repeat: no-repeat;
}

/* Traffic light colored list items - more specific selectors to avoid !important */
.custom-list li.traffic-light-red::before {
  background-color: #ff3b30; /* Red traffic light */
}

.custom-list li.traffic-light-yellow::before {
  background-color: #ffcc00; /* Yellow traffic light */
}

.custom-list li.traffic-light-green::before {
  background-color: #34c759; /* Green traffic light */
}

.shapy-background {
  width: 100%;
  background-image: url('assets/background-circle.svg'),
    url('assets/background-shape.svg');
  background-repeat: no-repeat;
  background-position: top left, bottom right;
}

.short-panel {
  width: 100%;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: hidden;
  background-image: url('assets/narrow-city.png');
  background-size: cover;
  background-position: center;
  padding: 30px;
  align-items: center;
}

.short-panel img {
  width: 100%;
  height: auto;
  display: block;
}

.page-content {
  min-height: 100vh;
  background: #0b0b22;
}

.team-page-content .shapy-background {
  min-height: 100vh;
}

.team-member-box {
  box-sizing: border-box;
  height: 566px;
  width: 390px;
  background: linear-gradient(
    53.07deg,
    rgba(49, 58, 91, 0.29) 10.64%,
    #313a5b 100.66%
  );
  backdrop-filter: blur(16.5px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 40.3101px;
}

.team-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  padding-top: 300px;
  padding-bottom: 300px;
  width: 100%;
  min-height: 100vh;
}

.team-member-content {
  position: absolute;
  bottom: 0;
  height: 347px;
  width: 390px;
  max-width: 100%;
  background: #1b1b33;
  border-radius: 40.3101px;
  padding: 30px;
}

.team-member-content p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  /* or 217% */

  /* Muted */
  color: rgba(255, 255, 255, 0.6);
}

.team-member-content h2 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  /* or 246% */
  color: white;
}

.team-member-content h3 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  /* or 344% */
  display: flex;
  align-items: center;

  color: #e2b088;
}

.team-member-box img {
  margin: 0 auto;
  position: absolute;
  bottom: 320px;
  width: 95%;
  height: auto;
  height: auto;
  display: block;
}

.contact-form {
  background: linear-gradient(145.04deg, #0c0b22 5.41%, #0c0b22 99.79%);
  border-radius: 40.5px;
  width: 680px;
  max-width: 100%;
  padding: 30px;
  margin: 300px 0;
}

.contact-form p {
  line-height: 30px;
}

.contact-form input {
  background: rgba(49, 36, 73, 0.5);
  border-radius: 22.5px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 97px;
  /* identical to box height, or 536% */
  width: 100%;
  color: rgba(255, 255, 255, 0.3);
  margin: 20px 0;
  padding: 0 20px;
}

.contact-form label {
  display: none;
}

.contact-form button {
  width: 100%;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 63px;
  /* or 243% */
  text-align: center;
  height: 82px;
  color: #1b1b33;
  background: #e2b088;
  border-radius: 15px;
}

.contact-form hr {
  border: 0.75px solid #e2b088;
  margin: 20px 0;
}

.contact-form button:hover {
  background: #d29250; /* Darker orange on hover */
}

.page {
  width: auto;
  height: auto;
}

.video-container {
  width: fit-content;
  max-height: 90vh;
  margin: 0 auto;
}

.demo-video {
  width: auto;
  max-width: 90vw;
  max-height: 90vh;
  object-fit: contain;
  display: block;
}

/* Adjust for very short heights */
@media (max-height: 600px) {
  #demo-video {
    max-height: 50vh;
  }
}

.scroll-arrow {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 24px;
  cursor: pointer;
  color: #ffffff;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.scroll-arrow:hover {
  opacity: 1;
}

.panel {
  position: relative; /* Ensure absolute positioning works within panels */
}

.video-container {
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: center;
  align-items: center;
  will-change: transform;
}

.demo-video {
  width: 100%;
  height: auto;
  border-radius: 8px;
  overflow: hidden;
}
